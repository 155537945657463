import React, { Fragment, useEffect } from "react";

import Footer from "../../components/global/Footer";
import Instagram from "../../components/global/Instagram";
import Header from "../../components/header/Header";
import PageTitle from "../../components/global/PageTitle";

/**
 * ContactUs page
 * @param options
 * @returns {*}
 * @constructor
 */
function ContactUs({ options }) {
  const onSubmitForm = (e) => {
    e.preventDefault();
  };

  /**
   * demo data
   */
  const contactUsData = {
    phone_1: "+961 71 577 666",
    email_1: "daniaaljandali@gmail.com",
  };
  useEffect(() => {
    if (options.mobileNav) {
      if (options.mobileNav) {
        options.onMobileNavClick();
      }
    }
  }, []);
  return (
    <Fragment>
      <Header options={options} />

      <PageTitle name="Contact" />

      {/* start contact-section */}
      <section className="contact-section contact-pg-section section-padding">
        <div className="container-1410">
          <div className="row">
            <div className="col col-lg-10 col-lg-offset-1">
              <div className="contact-info">
                <ul>
                  <li>
                    <i className="pe-7s-phone" />
                    <h4>Phone number</h4>
                    <p>
                      <a
                        href={
                          "tel:" + contactUsData.phone_1.replaceAll(" ", "")
                        }
                      >
                        {contactUsData.phone_1}
                      </a>
                    </p>
                  </li>
                  <li>
                    <i className="pe-7s-mail" />
                    <h4>Email us</h4>
                    <p>
                      <a href={"mailto:" + contactUsData.email_1}>
                        {contactUsData.email_1}
                      </a>{" "}
                      <br />
                    </p>
                  </li>
                </ul>
              </div>
              <div className="contact-form-col">
                <h2>Let’s talk to us</h2>
                <div className="contact-form">
                  <form
                    method="post"
                    className="contact-validation-active"
                    id="contact-form-main"
                  >
                    <div>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name*"
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email*"
                      />
                    </div>
                    <div className="fullwidth">
                      <select name="subject">
                        <option disabled="disabled">Contact subject</option>
                        <option>Subject 1</option>
                        <option>Subject 2</option>
                        <option>Subject 3</option>
                      </select>
                    </div>
                    <div className="fullwidth">
                      <textarea
                        name="note"
                        id="note"
                        placeholder="Case Description..."
                        defaultValue={""}
                      />
                    </div>
                    <div className="submit-area">
                      <button
                        onClick={onSubmitForm}
                        type="submit"
                        className="theme-btn"
                      >
                        Submit It Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end contact-section-s3 */}

      {/*  start contact-map */}
      {/* <section className="contact-map-section">
        <h2 className="hidden">Contact map</h2>
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13248.385886159778!2d35.4831412!3d33.8871692!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f1742ed970b19%3A0xeba9c2f0f5ef6ceb!2sVision%26More!5e0!3m2!1sen!2slb!4v1703229633764!5m2!1sen!2slb"
            width="600"
            height="450"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section> */}
      {/* end contact-map */}

      {/* <Instagram /> */}
      <Footer />
    </Fragment>
  );
}

export default ContactUs;
