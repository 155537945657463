import React, {Fragment} from 'react';

/**
 * product Quick View component
 * @param data
 * @param onQuickViewCloseClick
 * @returns {*}
 * @constructor
 */
function QuickView({data, onQuickViewCloseClick}) {

    return (
        <Fragment>
            <div className="quick-view-single-product activve-quick-view-single-product">
                <div className="view-single-product-inner clearfix">
                    <button className="btn quick-view-single-product-close-btn" onClick={onQuickViewCloseClick}><i
                        className="pe-7s-close-circle"/></button>
                    <div className="img-holder">
                        <img  style={{height:"353px"}}loading="lazy" src={process.env.PUBLIC_URL + data.mainImg} alt=""/>
                    </div>
                    <div className="product-details" style={{width:"75%"}}>
                        <h4>{data.content}</h4>
                        <div className="price">
                        {data.price2 ? (
                        <Fragment>
                               <span className="woocommerce-Price-currencySymbol">
                            {data.Symbol}
                          </span>
                          {data.price2 + " with belt"}
                          <br/>
                          <span className="woocommerce-Price-currencySymbol">
                            {data.Symbol}
                          </span>{" "}
                          {data.price + " without belt"}
                       
                        </Fragment>
                      ) : (
                        <Fragment>
                          <span className="woocommerce-Price-currencySymbol">
                            {data.Symbol}
                          </span>
                          {data.price}
                        </Fragment>
                      )}
                        </div>
                        {/* <div className="rating">
                            <i className="fi flaticon-star"/>
                            <i className="fi flaticon-star"/>
                            <i className="fi flaticon-star"/>
                            <i className="fi flaticon-star"/>
                            <i className="fi flaticon-star-social-favorite-middle-full"/>
                            <span>({data.reviewCounts} Customer review)</span>
                        </div> */}
                        {/* <p>{data.content}</p> */}
                        {/* <div className="thb-product-meta-before">
                            <div className="product_meta">
                                <span className="sku_wrapper">SKU: <span className="sku">{data.SKU}</span></span>
                                <span className="posted_in">
                                    Categories:
                                    {
                                        data.Categories.map((item, index) =>
                                            <a key={index}
                                               href={item.link}>{item.name} {data.Categories.length - 1 === index ? '' : ','}</a>
                                        )
                                    }
                                </span>
                                <span className="tagged_as">
                                    Tags:
                                    {
                                        data.Tags.map((item, index) =>
                                            <a key={index}
                                               href={item.link}>{item.name} {data.Tags.length - 1 === index ? '' : ','}</a>
                                        )
                                    }
                                </span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default QuickView;