import React, { Fragment } from "react";
import Slider from "react-slick";
import ReactTooltip from "react-tooltip";

import "../products/products.css";

/**
 * demo data
 */
import productsData from "../../data/products.json";
import { Link } from "react-router-dom";

/**
 * Recent Single Products component
 * @param onQuickViewClick
 * @returns {*}
 * @constructor
 */
function RecentSingleProducts({ onQuickViewClick , id , Categories }) {
  /**
   * slider settings
   * @type {{swipeToSlide: boolean, dots: boolean, infinite: boolean, responsive: *[], slidesToScroll: number, focusOnSelect: boolean, slidesToShow: number, autoplay: boolean, speed: number, autoplaySpeed: number}}
   */
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 300,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Fragment>
      <div className="realted-porduct">
        <h3>Related product</h3>
        <ul className="products product-row-slider">
          <Slider {...settings}>
            {productsData.filter((p)=>p.id !== parseInt(id)).map((item, index) => (
              <li key={index} className="product">
                <div className="product-holder">
                  {parseInt(item.price) < parseInt(item.oldPrice) ? (
                    <div className="product-badge discount">
                      {Math.round(
                        ((parseInt(item.price) - parseInt(item.oldPrice)) /
                          parseInt(item.price)) *
                          100
                      )}
                      %
                    </div>
                  ) : (
                    ""
                  )}
                  <Link to={"/product/" + item.id}>
                    <img
                      loading="lazy"
                      src={process.env.PUBLIC_URL + item.mainImg}
                      alt=""
                    />
                  </Link>
                  <div className="shop-action-wrap">
                    <ul className="shop-action">
                      <li>
                        <a
                          href="#"
                          title="Quick view!"
                          data-tip="Quick view!"
                          onClick={(e) => onQuickViewClick(e, item)}
                        >
                          <i className="fi flaticon-view" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="product-info">
                  <h4>
                    <Link to={"/product/" + item.id}>{item.content}</Link>
                  </h4>
                  <span className="woocommerce-Price-amount amount">
                    <ins>
                      <span className="woocommerce-Price-amount amount">
                        <bdi>
                        {item.price2 ? (
                        <Fragment>
                               <span className="woocommerce-Price-currencySymbol">
                            {item.Symbol}
                          </span>
                          {item.price2 + " with belt"}
                          <br/>
                          <span className="woocommerce-Price-currencySymbol">
                            {item.Symbol}
                          </span>{" "}
                          {item.price + " without belt"}
                       
                        </Fragment>
                      ) : (
                        <Fragment>
                          <span className="woocommerce-Price-currencySymbol">
                            {item.Symbol}
                          </span>
                          {item.price}
                        </Fragment>
                      )}
                        </bdi>
                      </span>
                    </ins>
                  </span>
                </div>
              </li>
            ))}
          </Slider>
        </ul>
      </div>
      <ReactTooltip className="tool-tip" effect="solid" />
    </Fragment>
  );
}

export default RecentSingleProducts;
