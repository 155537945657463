import React, { useState, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

import Footer from "../../components/global/Footer";
import Instagram from "../../components/global/Instagram";
import PageTitle from "../../components/global/PageTitle";
import Header from "../../components/header/Header";
import ProductInfoTabs from "../../components/products/ProductInfoTabs";
import QuickView from "../../components/products/QuickView";
import RecentSingleProducts from "../../components/products/RecentSingleProducts";

import "./shop.css";
import productsData from "../../data/products.json";

/**
 * single shop page with  Slider Images
 * @param options
 * @returns {*}
 * @constructor
 */
function ShopSliderImages({ options }) {
  const { id } = useParams();
  /**
   * states
   */
  const [showQuickView, setShowQuickView] = useState(false);
  const [quickViewData, setQuickViewData] = useState({});
  const [product, setProduct] = useState({
    id: 0,
    SKU: "",
    Categories: [
      {
        id: 0,
        name: "",
        link: "#",
      },
    ],
    Tags: [
      {
        id: 1,
        name: "Fluid",
        link: "#",
      },
    ],
    content: "",
    starts: 0,
    title: "",
    price: "",
    oldPrice: "",
    Symbol: "",
    mainImg: "",
    gallery: [
      {
        id: 0,
        img: "",
        thumb: "",
      },
    ],
  });

  /**
   * Handel Quick View Data
   */
  const HandelQuickViewData = (e, item) => {
    e.preventDefault();
    setShowQuickView(!showQuickView);
    setQuickViewData(item);
  };

  /**
   * Handel Quick View Close
   */
  const HandelQuickViewClose = (e) => {
    e.preventDefault();
    setShowQuickView(false);
    setQuickViewData({});
  };

  /**
   * slider settings
   */
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  useEffect(() => {
    if (productsData.some((p) => p.id === parseInt(id))) {
      const productbyid = productsData.filter((p) => p.id === parseInt(id));
      setProduct(productbyid[0]);
    }
  }, [id]);
  return (
    <Fragment>
      {showQuickView ? (
        <QuickView
          data={quickViewData}
          onQuickViewCloseClick={HandelQuickViewClose}
        />
      ) : (
        ""
      )}

      <Header options={options} />

      <PageTitle name="Shop single" />

      {/* start shop-single-section */}
      <section className="shop-single-section section-padding">
        <div className="container-1410">
          <div className="row">
            <div className="col col-md-6">
              <div className="shop-single-slider slider-thumbnail">
                <Slider {...settings}>
                  {product.gallery.map((item, index) => (
                    <div key={index}>
                      <img src={process.env.PUBLIC_URL + item.img} />
                    </div>
                  ))}
                </Slider>
                <div className="slider-nav"></div>
              </div>
            </div>
            <div className="col col-md-6">
              <div className="product-details">
                <h2>{product.content}</h2>
                <div className="price">
                    {
                        parseFloat(product.price) !== parseFloat(product.oldPrice) ? (
                            <Fragment>
                                 <span className="current">
                    {product.Symbol}
                    {product.price}
                  </span>
                  <span className="old">
                    {product.symbol}
                    {product.oldPrice}
                  </span>
                            </Fragment>
                        )
                    : (
                        <span className="current">
                              {product.price2 ? (
                        <Fragment>
                             {product.Symbol}
                          {product.price2 + " with belt"}
                          <br/>
                          {product.Symbol}
                          {product.price + " without belt"}
                       
                        </Fragment>
                      ) : (
                        <Fragment>
                          <span className="woocommerce-Price-currencySymbol">
                            {product.Symbol}
                          </span>
                          {product.price}
                        </Fragment>
                      )}
                        
                      </span>
                    )} 
                 
                </div>
                {/* <div className="rating">
                  <i className="fi flaticon-star" />
                  <i className="fi flaticon-star" />
                  <i className="fi flaticon-star" />
                  <i className="fi flaticon-star" />
                  <i className="fi flaticon-star-social-favorite-middle-full" />
                  <span>{product.reviewCount}</span>
                </div> */}
                {/* <p>{product.content}</p> */}
                
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
          {/* <div className="row">
            <div className="col col-md-8 col-md-offset-2">
              <ProductInfoTabs product={product} />
            </div>
          </div> */}
          {/* end row */}
          <div className="row">
            <div className="col col-xs-12">
              <RecentSingleProducts Categories={product.Categories} id={id} onQuickViewClick={HandelQuickViewData} />
            </div>
          </div>
        </div>
        {/* end of container */}
      </section>
      {/* end of shop-single-section */}
      {/* <Instagram /> */}
      <Footer />
    </Fragment>
  );
}

export default ShopSliderImages;
