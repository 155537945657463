import React, { useState, Fragment, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import Footer from "../../components/global/Footer";
import Instagram from "../../components/global/Instagram";
import Header from "../../components/header/Header";
import PageTitle from "../../components/global/PageTitle";
import Ordering from "../../components/shop/Ordering";
import QuickView from "../../components/products/QuickView";
import Pagination from "../../components/global/Pagination";
import OrderingToolbar from "../../components/shop/OrderingToolbar";
import Products from "../../components/shop/Products";

import "./shop.css";

/**
 * demo data
 */
import productsData from "../../data/products.json";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// const products = [...productsData];

/**
 * Shop page Full Width
 * @param options
 * @returns {*}
 * @constructor
 */
function FullWidth({ options }) {
  const location = useLocation();
  const {id} =useParams()

  const searchParams = new URLSearchParams(location.search);
  const searchParamsRef = useRef(searchParams);
  const [products, setProducts] = useState([]);

  /**
   * states
   */
  const [showQuickView, setShowQuickView] = useState(false);
  const [quickViewData, setQuickViewData] = useState({});
  const [ordering, setOrdering] = useState(1);
  const [orderBy, setOrderBy] = useState(1);

  /**
   * Handle Ordering Status
   */
  const HandleOrderingStatus = (event, data) => {
    event.preventDefault();
    setOrdering(data);
  };

  /**
   * Handel Quick View Data
   */
  const HandelQuickViewData = (e, item) => {
    e.preventDefault();
    setShowQuickView(!showQuickView);
    setQuickViewData(item);
  };

  /**
   * Handel Quick View Close
   */
  const HandelQuickViewClose = (e) => {
    e.preventDefault();
    setShowQuickView(false);
    setQuickViewData({});
  };
  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);
  useEffect(() => {
    let sortedProducts = [...products]; // Create a copy of the products data

    // Sort the products based on the orderBy value
    if (parseInt(orderBy) === 1) {
      sortedProducts.sort((a, b) => a.id - b.id);
    } else if (parseInt(orderBy) === 3) {
      sortedProducts.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    } else if (parseInt(orderBy) === 2) {
      sortedProducts.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    }

    setProducts(sortedProducts);
  }, [orderBy]);
  useEffect(() => {
    console.log(id)
    const handleSearchParamsChange = () => {
      const currentSearchParams = searchParamsRef.current;
      if (currentSearchParams && currentSearchParams.has("search")) {
        let key = currentSearchParams.get("search");
        let newArray = productsData.filter((item) => {
          let titleMatch = item.title.toLowerCase().includes(key.toLowerCase());
          let categoryMatch = item.Categories.some((category) =>
            category.name.toLowerCase().includes(key.toLowerCase())
          );
          let tagMatch = item.Tags.some((tag) =>
            tag.name.toLowerCase().includes(key.toLowerCase())
          );

          return titleMatch || categoryMatch || tagMatch;
        });
        console.log(newArray);
        setProducts(newArray);
      }else if(id && (parseInt(id) === 1 || parseInt(id) === 2 || parseInt(id) === 3 )){
        let newArray = productsData.filter((item) => {
          let categoryMatch = item.Categories.some((category) =>
            category.id.toString().toLowerCase().includes(id.toLowerCase())
          );

          return categoryMatch 
        });
        console.log(newArray);
        setProducts(newArray);
      } else {
        setProducts(productsData);
      }
    };

    handleSearchParamsChange();
  }, [id]);

  return (
    <Fragment>
      {showQuickView ? (
        <QuickView
          data={quickViewData}
          onQuickViewCloseClick={HandelQuickViewClose}
        />
      ) : (
        ""
      )}

      <Header options={options} />

      <PageTitle name="Shop" />

      {/* start shop-section */}
      <section className="shop-section shop-style-2 section-padding">
        <div className="container-1410">
          <div className="row">
            <div className="col col-xs-12">
              <div className="shop-area clearfix">
                <div className="woocommerce-content-wrap">
                  <div className="woocommerce-content-inner">
                    <div className="woocommerce-toolbar-top">
                      <p className="woocommerce-result-count">
                        Showing all {products.length} results
                      </p>

                      <OrderingToolbar
                        HandleOrderingStatus={HandleOrderingStatus}
                        ordering={ordering}
                      />

                      <Ordering orderBy={orderBy} setOrderBy={setOrderBy} />
                    </div>

                    <Products
                      HandelQuickViewData={HandelQuickViewData}
                      products={products}
                      ordering={ordering}
                    />
                  </div>

                  {/* <Pagination extraClass="" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end container */}
      </section>
      {/* end shop-section */}

      {/* <Instagram/> */}
      <Footer />
    </Fragment>
  );
}

export default FullWidth;
