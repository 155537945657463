import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Logo from "./Logo";
import "./navBar.css";

/**
 * nav bar component
 * @param options
 * @returns {*}
 * @constructor
 */
function Navbar({ options }) {
  const menu = [
    {
      Title: "Home",
      PageUrl: "/",
    },
    {
      Title: "Shop",
      PageUrl: "/shop",
    },
    {
      Title: "About",
      PageUrl: "/about",
    },
    {
      Title: "Contact",
      PageUrl: "/contact",
    },
  ];
  console.log(window.location.pathname)

  return (
    <Fragment>
      <div
        id="navbar"
        className={
          "navbar-collapse collapse navigation-holder " +
          (options.mobileNav ? "slideInn" : "")
        }
      >
        <button onClick={options.onMobileNavClick} className="close-navbar">
          <i className="ti-close" />
        </button>
        <ul className="nav navbar-nav">
          {menu.slice(0, Math.ceil(menu.length / 2)).map((m, index) => {
              return (
                <li key={index}>
                  <NavLink
                    to={m.PageUrl}
                    activeClassName={`${
                      window.location.pathname === m.PageUrl
                        ? "current-menu-item"
                        : ""
                    }`}
                  >
                    {m.Title}
                  </NavLink>
                </li>
              );
            
          })}

          <Logo />

          {menu.slice(Math.ceil(menu.length / 2)).map((m, index) => {
              return (
                <li key={index}>
                  <NavLink
                    to={m.PageUrl}
                    activeClassName={`${
                      window.location.pathname.includes(
                        m.Title.toLocaleLowerCase()
                      )
                        ? "current-menu-item"
                        : ""
                    }`}
                  >
                    {m.Title}
                  </NavLink>
                </li>
              );
            
          })}
        </ul>
      </div>
      {/* end of nav-collapse */}
    </Fragment>
  );
}

export default Navbar;
