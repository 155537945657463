import React, { Fragment } from "react";
import { Link } from "react-router-dom";

/**
 * Products
 * @param HandelQuickViewData
 * @param products
 * @param ordering
 * @returns {*}
 * @constructor
 */
function Products({ HandelQuickViewData, products, ordering }) {
  return (
    <Fragment>
      <ul
        className={
          "products list " +
          (ordering == 1 ? "default-column" : ordering == 2 ? "list-view" : "")
        }
      >
        {products.map((item, index) => (
          <li key={index} className="product">
            <div className="product-holder">
              {parseInt(item.price) < parseInt(item.oldPrice) ? (
                <div className="product-badge discount">
                  {Math.round(
                    ((parseInt(item.price) - parseInt(item.oldPrice)) /
                      parseInt(item.price)) *
                      100
                  )}
                  %
                </div>
              ) : (
                ""
              )}
              <Link to={"/product/" + item.id}>
                <img
                  loading="lazy"
                  src={process.env.PUBLIC_URL + item.mainImg}
                  alt=""
                />
              </Link>
              <div className="shop-action-wrap">
                <ul className="shop-action">
                  <li>
                    <a
                      href="#"
                      title="Quick view!"
                      data-tip="Quick view!"
                      onClick={(e) => HandelQuickViewData(e, item)}
                    >
                      <i className="fi flaticon-view" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="product-info">
              <h4>
                <Link to={"/product/" + item.id}>{item.content}</Link>
              </h4>
              <span className="woocommerce-Price-amount amount">
                <ins>
                  <span className="woocommerce-Price-amount amount">
                    <bdi>
                      {item.price2 ? (
                        <Fragment>
                               <span className="woocommerce-Price-currencySymbol">
                            {item.Symbol}
                          </span>
                          {item.price2 + " with belt"}
                          <br/>
                          <span className="woocommerce-Price-currencySymbol">
                            {item.Symbol}
                          </span>{" "}
                          {item.price + " without belt"}
                       
                        </Fragment>
                      ) : (
                        <Fragment>
                          <span className="woocommerce-Price-currencySymbol">
                            {item.Symbol}
                          </span>
                          {item.price}
                        </Fragment>
                      )}
                    </bdi>
                  </span>
                </ins>
                {parseInt(item.price) < parseInt(item.oldPrice) ? (
                  <del>
                    <span className="woocommerce-Price-amount amount">
                      <bdi>
                        <span className="woocommerce-Price-currencySymbol">
                          {item.Symbol}
                        </span>
                        {item.oldPrice}
                      </bdi>
                    </span>
                  </del>
                ) : (
                  ""
                )}
              </span>
              {/* <p className="product-description">{item.content}</p> */}
            </div>
          </li>
        ))}
      </ul>
    </Fragment>
  );
}

export default Products;
