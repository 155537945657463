import React, {Fragment} from 'react';

/**
 * Contact Widget
 * @returns {*}
 * @constructor
 */
function ContactWidget() {

    return (
        <Fragment>
            <div className="widget contact-widget">
                <h3>Contact info</h3>
                <ul>
                    <li><a href='tel:+96171577666'>Phone:+961 71 577 666</a> </li>
                    <li><a href='mailto:daniaaljandali@gmail.com'>Email:daniaaljandali@gmail.com</a> </li>
                </ul>
            </div>
        </Fragment>
    );
}

export default ContactWidget;