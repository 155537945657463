import React, { Fragment } from "react";

/**
 * product Ordering component
 * @returns {*}
 * @constructor
 */
function Ordering({ orderBy, setOrderBy }) {
  console.log(orderBy)
  return (
    <Fragment>
      <form className="woocommerce-ordering" method="get">
        <select
          name="orderby"
          className="orderby"
          onChange={(e) => setOrderBy(parseInt(e.target.value))}
          value={orderBy}
        >
          <option value={1}>Default sorting</option>
          <option value={2}>Sort by price: low to high</option>
          <option value={3}>Sort by price: high to low</option>
        </select>
        <input type="hidden" name="post_type" defaultValue="product" />
      </form>
    </Fragment>
  );
}

export default Ordering;
